/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import Loader from "@common/loader"
import { Options } from "@common/types"
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react"
import { isEmpty, isNullOrEmpty, joinClassNames } from "@common/lib/util"
import SearchBar from "@common/searchBar"
import { Fragment, useEffect, useState } from "react"
import EmptyList from "./emptyList"
import {
  DropdownOption,
  DropdownOptionContent,
} from "@common/dropdowns/dropdownOption"
import ListSection from "./listSection"
import {
  getListedOptionClasses,
  sectionException,
  useDropdown,
} from "@common/hooks/useDropdown"
import { useInView } from "react-intersection-observer"
import Svg from "@common/svg"
import { VariantProps, cva } from "class-variance-authority"
import Alert from "@common/alert"

// TODO - interface cleanup
// should accept id compulsarily
// rename - defaultSelectedKey -> selectedKey
// rename - placeHolderText -> placeholder
// remove required (validation moved to form)
// remove buttonWidthClasses (should be w-full of parent)
// remove isLoading. If options is undefined, loader can show.
// why is sortOptions required? Remove if unnecessary
// onSelect - any reason to expose event?
// onSelect - key should be string (since this is single mode only)
// upgrade to new headless version so that dropdown opens beyond parent container
// test - if key press scroll works
// what's the right interface for groups/sections

type ListBoxBaseProps = {
  id?: string
  defaultSelectedKey?: string
  onSelect?: (key: any, event?: any) => void
  options?: Options
  disabled?: boolean
  enableSearch?: boolean
  isLoading?: boolean
  sortOptions?: boolean
  placeHolderText?: string
  buttonWidthClasses?: string
  optionWidthClasses?: string
  enableReset?: boolean
  resetText?: string
  required?: boolean
}

const ListBoxVariants = cva("", {
  variants: {
    size: {
      sm: "size-sm",
      xs: "size-xs",
    },
  },
  defaultVariants: {
    size: "xs",
  },
})

export interface ListBoxProps
  extends ListBoxBaseProps,
    VariantProps<typeof ListBoxVariants> {}

export default function ListboxStyled({
  placeHolderText = "Select",
  options,
  onSelect,
  defaultSelectedKey,
  required = false,
  disabled = false,
  sortOptions = false,
  enableSearch = false,
  isLoading = false,
  buttonWidthClasses = "w-full",
  optionWidthClasses,
  enableReset = false,
  resetText = "clear selection",
  size,
}: ListBoxProps) {
  const {
    groupedOptions,
    selectedKeys,
    optionsArray,
    handleInputChange,
    setSelectedKeys,
  } = useDropdown(
    options,
    sortOptions,
    defaultSelectedKey ? [defaultSelectedKey] : []
  )

  const selectedKey = isNullOrEmpty(selectedKeys) ? undefined : selectedKeys[0]

  useEffect(() => {
    if (defaultSelectedKey != selectedKey) setSelectedKeys([defaultSelectedKey])
  }, [defaultSelectedKey])

  const [showError, setShowError] = useState<boolean>(false)
  const { ref, inView: areOptionsVisible } = useInView() //dont show error when options are visible (handled in <ComboboxStyled /> by onfocus)

  const buttonClasses = joinClassNames(
    "bg-background text-foreground rounded-md border relative text-left cursor-pointer text-sm overflow-hidden disabled:cursor-not-allowed disabled:opacity-50 transition-colors delay-75",
    !areOptionsVisible && showError && "border-destructive",
    buttonWidthClasses,
    size == "sm" ? "text-xs" : "text-sm"
  )
  const listOptionsClasses = joinClassNames(
    optionWidthClasses ?? "w-[var(--button-width)]",
    "list-option z-50"
  )

  const handleBlur = () => {
    if (required && !selectedKey) {
      setShowError(true)
    }
  }

  function handleChange(selectedKey: string) {
    setShowError(false)
    setSelectedKeys([selectedKey])
    if (onSelect) onSelect(selectedKey)
  }

  const selectedOption = optionsArray.filter((option) => option.key == selectedKey)

  const optionsLength = Object.keys(options || {}).length
  const showSearchBar = enableSearch && optionsLength > 1

  return (
    <>
      <Listbox
        disabled={disabled}
        value={selectedKey}
        onChange={handleChange}
        as="div"
        onBlur={handleBlur}
      >
        {isLoading ? (
          <div className={buttonClasses}>
            <div className={ListBoxVariants({ size })}>
              <Loader type="spinWithText" />
            </div>
          </div>
        ) : (
          <ListboxButton
            onClick={() => handleInputChange("")}
            className={buttonClasses}
          >
            {!isEmpty(selectedOption) ? (
              <div
                className={joinClassNames(
                  optionsLength !== 1 ? "w-[calc(100%_-_20px)]" : "w-full"
                )}
              >
                <DropdownOption
                  option={selectedOption[0]}
                  isHoverable={false}
                  size={size}
                />
              </div>
            ) : (
              <div
                className={joinClassNames(
                  ListBoxVariants({ size }),
                  "text-muted-foreground"
                )}
              >
                {placeHolderText}
              </div>
            )}
            {/* ptionsLength == 1 👉🏻 makes sure no toggle for one option */}
            {optionsLength == 1 ? null : (
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <Svg
                  name="arrow-up-down"
                  classes="h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              </span>
            )}
          </ListboxButton>
        )}
        <ListboxOptions
          ref={ref}
          className={listOptionsClasses}
          anchor="bottom start"
          transition
          modal={false}
        >
          {(showSearchBar || (enableReset && selectedKey)) && (
            <div className="sticky top-0 m-2 space-y-1 text-center backdrop-blur-sm bg-background z-20">
              {showSearchBar ? (
                <div className="rounded-md border bg-background px-2">
                  <SearchBar
                    onChange={handleInputChange}
                    onSubmit={undefined}
                    renderHelpContent={undefined}
                    value={undefined}
                    placeholder={undefined}
                  />
                </div>
              ) : null}

              {enableReset && selectedKey ? (
                <button
                  onClick={() => {
                    if (onSelect) onSelect(undefined)
                    setSelectedKeys([])
                    handleInputChange("")
                  }}
                  className="text-secondary border rounded-md w-full px-2 py-1 text-xs disabled:opacity-50"
                >
                  {resetText}
                </button>
              ) : null}
            </div>
          )}

          <div className="relative">
            {isNullOrEmpty(groupedOptions) ? (
              <EmptyList />
            ) : (
              Object.entries(groupedOptions).map(([section, options]) => (
                <Fragment key={section}>
                  {section !== sectionException.other && (
                    <ListSection sectionName={section} />
                  )}
                  {options?.map((option) => {
                    return (
                      <ListboxOption
                        key={option.key}
                        value={option.key}
                        disabled={option.disabled}
                        className={({ focus }) =>
                          getListedOptionClasses(
                            focus,
                            option.disabled || disabled,
                            true,
                            //@ts-ignore
                            size
                          )
                        }
                      >
                        {({ selected }) => (
                          <DropdownOptionContent
                            icon={option.icon}
                            display={option.display}
                            selected={selected}
                            showSelectedIcon={true}
                          />
                        )}
                      </ListboxOption>
                    )
                  })}
                </Fragment>
              ))
            )}
          </div>
        </ListboxOptions>
      </Listbox>
      {!areOptionsVisible && showError && (
        <Alert variant="simpleError">This field is required</Alert>
      )}
    </>
  )
}

/**
 * Test cases:
 * 1) Make sure default mode and compact mode height matches other similar input components
 */
