// React imports
import { useEffect, useRef, useState } from "react"

// Headless UI and Heroicons
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react"

// Common components
import EmptyList from "@common/dropdowns/emptyList"
import {
  DropdownOption,
  DropdownOptionContent,
} from "@common/dropdowns/dropdownOption"
import ListSection from "@common/dropdowns/listSection"
import Loader from "@common/loader"
import OptionForm from "@common/optionCreate"
import Tag from "@common/tags/tag"
import Tags from "@common/tags/tags"
import Svg from "@common/svg"
import { VariantProps, cva } from "class-variance-authority"
import Alert from "@common/alert"

// Hooks and utilities
import {
  getListedOptionClasses,
  sectionException,
  useDropdown,
} from "@common/hooks/useDropdown"
import {
  isArray,
  isArraysEqual,
  isNullOrEmpty,
  joinClassNames,
} from "@common/lib/util"

// Types
import { Options } from "@common/types"

// TODO (v2) interface cleanup
// remove required (handled via form validations)
// id compulsory
// enableOptionCreate / handleOptionCreate -> change to support inline option creation by default (with the option to use click callback to support current modal flow requirement)
// rename defaultSelectedKeys -> selectedKeys
// interface for groups/sections

type ComboboxStyledBaseProps = {
  handleOptionCreate?: (value: string) => void
  singleSelectMode?: boolean
  sortOptions?: boolean
  maxSelectCount?: number
  minSelectCount?: number
  enableOptionCreate?: boolean
  defaultSelectedKeys?: any[]
  options?: Options
  placeHolderText?: string
  onSelect?: (selectedKeys: Array<string>) => void
  disabled?: boolean
  required?: boolean
  id?: string
}

const ComboBoxVariants = cva(
  "bg-transparent border-none group text-primary focus:ring-0 px-0 w-full border rounded-md focus:outline-none placeholder:text-muted-foreground bg-muted ",
  {
    variants: {
      size: {
        sm: "size-sm",
        xs: "size-xs",
      },
    },
    defaultVariants: {
      size: "xs",
    },
  }
)
interface ComboboxStyledProps
  extends ComboboxStyledBaseProps,
    VariantProps<typeof ComboBoxVariants> {}

export default function ComboboxStyled({
  placeHolderText = "search or select",
  options,
  onSelect,
  defaultSelectedKeys = [],
  required = false,
  disabled = false,
  singleSelectMode = false,
  sortOptions = false,
  maxSelectCount,
  minSelectCount,
  handleOptionCreate,
  enableOptionCreate,
  size = "sm",
}: ComboboxStyledProps) {
  const {
    groupedOptions,
    selectedKeys,
    // optionsArray,
    handleInputChange,
    setSelectedKeys,
  } = useDropdown(options, sortOptions, defaultSelectedKeys)

  const [query, setQuery] = useState<string>("")
  const [isMaxed, setIsMaxed] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const inputRef = useRef<any>(null)
  useEffect(() => {
    updateIsMaxed(defaultSelectedKeys)
    if (defaultSelectedKeys && !isArraysEqual(defaultSelectedKeys, selectedKeys))
      setSelectedKeys(defaultSelectedKeys)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSelectedKeys, maxSelectCount])

  function updateIsMaxed(keys: any) {
    setIsMaxed(maxSelectCount ? maxSelectCount <= keys.length : false)
  }

  const handleRemove = (key: any) => {
    if (disabled) return
    const newSelectedKeys = selectedKeys.filter((_key) => _key != key)
    updateIsMaxed(newSelectedKeys)
    setSelectedKeys(newSelectedKeys)
    if (getErrorMessage(newSelectedKeys)) setShowError(true)
    if (onSelect) onSelect(newSelectedKeys)
  }

  const handleKeyDown = (e: any) => {
    if (e.key == "Backspace" && query == "" && !disabled) {
      const newSelectedKeys = selectedKeys.slice(0, -1)
      updateIsMaxed(newSelectedKeys)
      setSelectedKeys(newSelectedKeys)
      if (getErrorMessage(newSelectedKeys)) setShowError(true)
    }
  }

  const handleChange = (selected: any) => {
    if (!selected) return
    const selectedKeys = (Array.isArray(selected) ? selected : [selected])?.map(
      (option: any) => option.key
    )
    const isMaxed = maxSelectCount ? maxSelectCount < selectedKeys.length : false
    updateIsMaxed(selectedKeys)
    if (!isMaxed) {
      setSelectedKeys(selectedKeys)
      onSelect?.(selectedKeys)
    }
  }

  const isBelowMinSelectCount = minSelectCount
    ? selectedKeys.length < minSelectCount
    : false

  const getErrorMessage = (selectedKeys: any) => {
    let message = ""
    if (required && isNullOrEmpty(selectedKeys)) message = "This field is required."
    else if (isBelowMinSelectCount)
      message = `Minimum ${minSelectCount} items needs to be selected.`
    return message
  }

  const errorMessage = getErrorMessage(selectedKeys)
  const isError = errorMessage && showError
  const selectedOptions = !isNullOrEmpty(options)
    ? selectedKeys.map((key) => options[key]).filter(Boolean)
    : []

  const containerClass = joinClassNames(
    "flex flex-col bg-transparent text-primary rounded-md relative w-full border text-left text-sm transition-colors delay-75",
    isError && "border-destructive",
    singleSelectMode ? " " : "px-2",
    disabled ? " opacity-50 cursor-not-allowed " : " cursor-pointer "
  )

  const clearQuery = () => {
    setQuery("")
    handleInputChange("")
    setTimeout(() => {
      if (inputRef.current) inputRef.current.value = ""
    }, 0)
  }
  const selectedItem =
    !isNullOrEmpty(selectedKeys) && options && options[selectedKeys[0]]

  return (
    <>
      <Combobox
        value={singleSelectMode ? selectedOptions[0] : selectedOptions}
        onChange={handleChange}
        multiple={singleSelectMode ? false : true}
        //ignore this type error, it's working on build
        by="key"
        immediate
        disabled={disabled}
        onClose={clearQuery}
      >
        {({ open }) => (
          <div
            tabIndex={0}
            className="overflow-hidden"
            onBlur={() => {
              if (errorMessage) setShowError(true)
            }}
            onFocus={() => {
              setShowError(false)
            }}
          >
            <div className={containerClass}>
              {!isNullOrEmpty(selectedKeys) && !singleSelectMode && options && (
                <div
                  className="py-2"
                  onFocus={(e) => {
                    //Prevent focus on input when tags are clicked for remove
                    e.stopPropagation()
                  }}
                >
                  <Tags maxShowTagLimit={10} isClickType={false}>
                    {selectedKeys.map((key) => (
                      <Tag
                        key={key}
                        cross={true}
                        onCrossClick={() => handleRemove(key)}
                        size={size == "xs" ? "xs" : "default"}
                      >
                        {options[key]?.display}
                      </Tag>
                    ))}
                  </Tags>
                </div>
              )}

              {!options && !query ? (
                <div className="flex justify-between items-center">
                  <div className="w-full">
                    <Loader />
                  </div>
                  <input
                    className={joinClassNames(ComboBoxVariants({ size }), "w-1")} // To inherit height properties of input inside Combobox.Input
                  />
                </div>
              ) : (
                <>
                  {singleSelectMode && selectedItem && !open ? (
                    <ComboboxButton className="text-left flex items-center px-1">
                      {/* 1️⃣ ListBoxOption pretending to be a combobox in singleSelectMode */}
                      <DropdownOption
                        option={{ ...selectedItem, disabled: false }}
                        selected={false}
                        isHoverable={false}
                        size={size}
                      />
                      <Svg
                        name="arrow-up-down"
                        classes="w-4 h-4 animate-loadFadeUp text-secondary"
                      />
                    </ComboboxButton>
                  ) : (
                    <div
                      className={`flex justify-start gap-2 items-center  ${
                        singleSelectMode ? " px-2 py-0 " : "  "
                      }`}
                    >
                      {open && (
                        <Svg
                          classes="w-4 h-4 animate-loadFadeUp text-secondary"
                          name="search"
                        />
                      )}
                      <ComboboxInput
                        className={ComboBoxVariants({ size })}
                        onChange={(e) => {
                          handleInputChange(e.target.value)
                          setQuery(e.target.value)
                        }}
                        onKeyDown={handleKeyDown}
                        placeholder={placeHolderText}
                        displayValue={(option: any) => option?.display}
                        onFocus={clearQuery}
                        ref={inputRef}
                      />
                      <ComboboxButton>
                        <Svg
                          name="arrow-up-down"
                          classes="w-4 h-4 animate-loadFadeUp text-secondary"
                        />
                      </ComboboxButton>
                    </div>
                  )}
                </>
              )}
            </div>

            <ComboboxOptions
              as="div"
              className="list-option w-[calc(var(--input-width)+var(--button-width)+52px)]"
              anchor="bottom"
            >
              {isNullOrEmpty(groupedOptions) && <EmptyList />}
              {Object.entries(groupedOptions).map(([section, options]) => (
                <div key={section}>
                  {section !== sectionException.other && (
                    <ListSection sectionName={section} />
                  )}
                  {options?.map((option) => {
                    const isDisabled =
                      option.disabled ||
                      disabled ||
                      (isMaxed && !selectedKeys.includes(option.key))
                    return (
                      <ComboboxOption
                        key={option.key}
                        className={({ focus }) =>
                          getListedOptionClasses(focus, isDisabled,  size)
                        }
                        disabled={isDisabled}
                        value={option}
                      >
                        {({ selected }) => (
                          <div className="flex justify-start gap-2 items-center w-full">
                            <DropdownOptionContent
                              icon={option.icon}
                              display={option.display}
                              selected={selected}
                              showSelectedIcon={singleSelectMode}
                              showCheckedIcon={!singleSelectMode}
                            />
                          </div>
                        )}
                      </ComboboxOption>
                    )
                  })}
                </div>
              ))}
              {!disabled && handleOptionCreate && enableOptionCreate && (
                <OptionForm query={query} handleOptionCreate={handleOptionCreate} />
              )}
            </ComboboxOptions>
          </div>
        )}
      </Combobox>
      {!!isError && <Alert variant="simpleError">This field is required</Alert>}
    </>
  )
}

/**
 * Test cases:
 * 1) Make sure default mode and compact mode height matches other similar input components
 */
