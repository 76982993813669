import Label from "@common/forms/label"
import { isNullOrEmpty, joinClassNames } from "@common/lib/util"
import DatePicker, { DatePickerProps } from "@common/datePickers/datePicker"
import { useEffect, useState } from "react"
export type Range = [string, string]

type DatePickerRangeProps = Pick<DatePickerProps, "size"> & {
  range?: Range
  onChange: (range: Range) => void
  required?: boolean
}
export default function DatePickerRange({
  range,
  onChange,
  required,
  size = "xs",
}: DatePickerRangeProps) {
  // State for selected range
  const [selectedRange, setSelectedRange] = useState<Range | undefined>(range)

  // Handles range selection from DatePicker
  useEffect(() => {
    if (!isNullOrEmpty(range)) setSelectedRange(range)
  }, [range])

  function handleRangeChange(date: string, index: number) {
    setSelectedRange((prevRange) => {
      const updatedRange = [...(prevRange || [])] as Range
      updatedRange[index] = date
      onChange(updatedRange)
      return updatedRange
    })
  }

  return (
    <div className="border rounded-md p-4 flex flex-col md:flex-row justify-between w-full ">
      <Label label="From" font="normal" required={required}>
        <DatePicker
          onChange={(fromDate) => handleRangeChange(fromDate, 0)}
          date={selectedRange?.[0]}
          required={required}
          size={size}
        />
      </Label>
      {/* connecting line */}
      <div
        className={joinClassNames(
          `h-20 md:h-0 md:w-full border-l md:border-t `,
          size == "sm" ? "md:mt-[2.7rem]" : "md:mt-[2.5rem]"
        )}
      />
      <Label label="To" font="normal" required={required}>
        <DatePicker
          onChange={(toDate) => handleRangeChange(toDate, 1)}
          date={selectedRange?.[1]}
          required={required}
          size={size}
        />
      </Label>
    </div>
  )
}

/**
 * Test cases:
 * 1) Make sure default mode and compact mode height matches other similar input components
 */
