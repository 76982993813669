import React, { useState, useEffect, useRef } from "react"
import Tag from "@common/tags/tag"
import { joinClassNames } from "@common/lib/util"
import { VariantProps, cva } from "class-variance-authority"
import Alert from "@common/alert"

interface InputBaseProps {
  field?: "input" | "textarea" | "multi_input"
  placeholder?: string
  value?: string | string[]
  id?: string
  type?: any
  required?: boolean
  minChars?: number
  maxChars?: number
  onChange?: (value: any, message?: string) => void
  autoComplete?: string
  rows?: number
  fieldClasses?: string
  fieldStyles?: React.CSSProperties
  min?: number
  max?: number
  pattern?: string
  disabled?: boolean
  accept?: string
}

interface TagValue {
  value: string
  isValid: boolean
}

const InputVariants = cva("input", {
  variants: {
    size: {
      sm: "size-sm",
      xs: "size-xs",
    },
  },
  defaultVariants: {
    size: "xs",
  },
})

export interface InputProps
  extends InputBaseProps,
    VariantProps<typeof InputVariants> {}

function Input({
  field = "input",
  placeholder,
  value,
  type,
  required,
  minChars,
  maxChars,
  onChange,
  autoComplete,
  rows,
  fieldClasses,
  fieldStyles,
  min,
  max,
  pattern,
  disabled,
  size,
  accept,
  id,
}: InputProps) {
  const [errorMessage, setErrorMessage] = useState<any>(null)
  const [text, setText] = useState<any>(value || "")
  const [showError, setShowError] = useState<any>(false)
  const [multiInputValues, setMultiInputValues] = useState<any>([])
  const inputRef = useRef<any>(null)

  useEffect(() => {
    if (
      ((type === "number" ? text != undefined : text?.length > 0) ||
        multiInputValues.length > 0) &&
      value
    ) {
      return /* Once initial value is set(which can be delayed and set after initial render),  do not set text or multiInputs on value change  */
    }
    if (Array.isArray(value)) {
      setText("")
      if (multiInputValues.length == 0) {
        const values: TagValue[] = value.map((value) => ({
          value,
          isValid: !checkError(value || ""),
        }))
        setMultiInputValues(values)
      }
    } else if (text != value) {
      // if the text is same we don't need to update it with the same value
      setText(type === "number" ? value : value || "")
    }
  }, [value])

  const isError = showError && errorMessage && errorMessage.length > 0
  const isMultiInputError =
    multiInputValues.flat().length == 0
      ? false
      : multiInputValues.flat().some((value: any) => !value.isValid)

  const handleKeyDown = (e: any) => {
    const value = text.trim()
    if (e.key === "Backspace" && !text) {
      handleRemove(multiInputValues.at(-1))
    }
    const isKeyMatched = ["Tab", "Enter", ","].includes(e.key)

    if (value && isKeyMatched) {
      e.preventDefault()
      handleMultiInputValue(value)
    }
  }

  const handleRemove = (tag: any) => {
    const newValues = multiInputValues.filter((obj: any) => obj.value !== tag.value)
    setMultiInputValues(newValues)
    if (onChange) {
      onChange(newValues?.map((obj: any) => obj.value))
    }
  }

  const isEmpty =
    multiInputValues.length == 0 ||
    (multiInputValues.some((_multiInputValues: any) =>
      Array.isArray(_multiInputValues)
    ) &&
      multiInputValues?.[0]?.length == 0)

  return (
    <>
      {renderField()}
      {isError && (
        <Alert variant="simpleError">{isError ? errorMessage : null}</Alert>
      )}
    </>
  )

  function handleMultiInputFocus() {
    inputRef?.current?.focus()
    handleOnFocus()
  }
  function handleOnFocus() {
    setErrorMessage(null)
    setShowError(false)
  }

  function handleMultiInputValue(value: any) {
    const message = checkError(value)
    const isDuplicate = multiInputValues.some((v: any) => v.value == value && value)

    if (isDuplicate) {
      setShowError(true)
      setErrorMessage("Duplicate values are not allowed")
    } else if (message) {
      setShowError(true)
    } else if (value && !isDuplicate) {
      const newValues = [...multiInputValues, { value, isValid: !message }]
      setMultiInputValues(newValues)
      setText("")

      if (onChange) {
        onChange(
          newValues.map((obj) => obj.value),
          // id,
          message
        )
      }
    }
  }

  function handleOnBlur(isTag?: any) {
    if (field === "multi_input" && text) {
      handleMultiInputValue(text.trim())
    } else {
      checkError(text, isTag)
      setShowError(true)
    }
  }

  function handleChange(input?: any, isMultiInput?: any) {
    if (type === "file") onChange?.(input, id)
    else {
      let value =
        type === "number" && !isNaN(input) && input !== "" ? Number(input) : input
      if (type === "number" && value === "") value = undefined // use `undefined` for empty value if the type is number
      setText(value)
      if (!isMultiInput) {
        if (onChange) onChange(value, checkError(value))
      }
    }
  }

  function checkError(input: any, isTag?: any) {
    let message = ""
    const isFieldRequired = required == true
    const inputLength = type !== "number" ? input.length : 0
    if (isTag && isFieldRequired && multiInputValues.length == 0) {
      message = `This field is required.`
    } else {
      if (
        isFieldRequired &&
        inputLength == 0 &&
        field != "multi_input" &&
        type !== "number"
      )
        message = `This field is required.`
      if (type === "number" && isFieldRequired && input == undefined) {
        message = `This field is required.`
      }
      if (minChars && inputLength < minChars)
        message = `This field should have minimum ${minChars} characters.`
      if (maxChars && inputLength > maxChars) {
        message = `This field should not have more than ${maxChars} characters.`
      }
      if (min !== undefined && Number(input) < min) {
        message = `This field should not have less than ${max}.`
      }
      if (max !== undefined && Number(input) > max) {
        message = `This field should not have more than ${min}.`
      }
      if (type == "email" && (input.indexOf("@") == -1 || input.indexOf(".") == -1))
        message = "Please enter a valid email."
      if (pattern) {
        const regExp = new RegExp(pattern, "i")
        if (!input || !regExp.test(input?.trim())) message = `Enter a valid value`
      }
    }
    setErrorMessage(message)
    return message
  }

  function renderField() {
    // TODO: border border-border find root cause
    const classes = joinClassNames(
      InputVariants({ size }),
      fieldClasses,
      isError && "border-destructive"
    )

    // const holder =
    //   labelPosition == "inline"
    //     ? label + (required == true ? " *" : "")
    //     : placeholder

    if (field == "multi_input") {
      return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          className={joinClassNames(
            classes,
            isMultiInputError ? " border-destructive" : "",
            " py-0 px-0"
          )}
          onClick={handleMultiInputFocus}
        >
          {renderTags()}
        </div>
      )
    }
    if (field == "textarea")
      return (
        <textarea
          value={text}
          rows={rows || 4}
          onChange={(e) => handleChange(e.target.value)}
          onFocus={() => handleOnFocus()}
          onBlur={() => handleOnBlur()}
          id={id}
          autoComplete={autoComplete || type}
          required={required}
          placeholder={placeholder}
          className={classes}
          style={fieldStyles}
        />
        /* {text}
        </textarea> */
      )

    return (
      <input
        value={type === "file" ? undefined : text}
        onChange={(e) =>
          handleChange(type === "file" ? e.target.files : e.target.value)
        }
        onFocus={() => handleOnFocus()}
        onBlur={() => handleOnBlur()}
        id={id}
        type={type}
        autoComplete={autoComplete || type}
        required={required}
        placeholder={placeholder}
        className={classes}
        style={fieldStyles}
        pattern={pattern}
        min={min}
        disabled={disabled}
        accept={accept}
      />
    )
  }
  function renderTags() {
    return (
      <ul className="flex flex-wrap items-center w-full shrink-0 min-w-0 h-full gap-x-2 gap-y-2 ">
        {multiInputValues.map((tag: any) => (
          <li key={tag.value}>
            <Tag
              color={!tag.isValid ? "red" : "transparent"}
              cross
              onCrossClick={() => handleRemove(tag)}
            >
              {tag?.value}
            </Tag>
          </li>
        ))}
        <li>
          <input
            ref={inputRef}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            value={text}
            onChange={(e) => handleChange(e.target.value, true)}
            onKeyDown={handleKeyDown}
            onBlur={() => handleOnBlur(true)}
            className="shrink focus:outline-none bg-transparent min-w-0 max-w-[12rem] focus:border-0 focus:ring-0 border-0 h-full py-0 px-0"
            placeholder={isEmpty ? placeholder : ""}
            id={id}
            type={type}
            autoComplete={autoComplete || type}
            style={fieldStyles}
            pattern={pattern}
          />
        </li>
      </ul>
    )
  }
}

export default Input

/**
 * Test cases:
 * 1) Make sure default mode and compact mode height matches other similar input components
 */
