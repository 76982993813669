import { Header } from "@common/header/headerUnit"
import { ReactNode } from "react"

export default function ListSection({ sectionName }: { sectionName: ReactNode }) {
  return (
    <>
      <div className="sticky -top- z-40 px-2 mt-4 backdrop-blur-sm  ">
        <Header.ShortHeader color="primary">{sectionName}</Header.ShortHeader>
      </div>
      {/* <div className="absolute top-0 bottom-0 h-full left-3 w-[1px] bg-border z-30 " /> */}
    </>
  )
}
