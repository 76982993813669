import useDebouncedCallback from "@common/hooks/useDebounceCallback"
import { isFunction } from "@common/lib/util"
import { useEffect, useState } from "react"

type RangeProps = {
  id: string
  required?: boolean
  value: number
  min: number
  max: number
  step: number
  onChange: (value: any) => void
  debounce?: boolean
}
export default function Range({
  value,
  min,
  max,
  step,
  onChange,
  required,
  id,
  debounce = false,
}: RangeProps) {
  const [rangeValue, setRangeValue] = useState<number>(value || step)

  useEffect(() => {
    if (value != rangeValue && value !== undefined) setRangeValue(value)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const debouncedOnChange = useDebouncedCallback(onChange, 1000)

  function handleRangeChange(value: number) {
    setRangeValue(value)
    if (isFunction(onChange)) {
      debounce ? debouncedOnChange(value) : onChange(value)
    }
  }

  return (
    <>
      <div className="flex justify-between text-xxs-r items-center gap-2 font-extrabold text-zinc-300 dark:text-zinc-600">
        <div>{min}</div>
        <input
          value={rangeValue}
          onInput={(event: any) => handleRangeChange(event?.target?.value)}
          type="range"
          min={min || 0}
          max={max}
          step={step}
          id={id}
          required={required}
          className="w-full h-1 bg-zinc-300 dark:bg-zinc-600 rounded-lg appearance-none cursor-pointer "
        />
        <div>{max}</div>
      </div>
      <div
        className="flex justify-end text-xs font-bold"
        style={{ width: `${(value / max) * 100}%` }}
      >
        {value}
      </div>
    </>
  )
}
