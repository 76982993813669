import React from "react"

type EmptyListProps = {
  message?: string
}

export default function EmptyList({ message = "No Match Found" }: EmptyListProps) {
  return (
    <div className="menu-item cursor-pointer hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-black dark:hover:text-gray-300">
      <div className="flex items-center">{message}</div>
    </div>
  )
}
