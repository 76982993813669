import { useEffect, useState } from "react"
import CheckboxStyled from "@common/forms/inputs/checkboxStyled"
import Tag from "@common/tags/tag"
import ListSearch from "@common/listSearch"
import {
  isEmpty,
  isNullOrEmpty,
  isObjectsEqual,
  joinClassNames,
} from "@common/lib/util"
import { Options } from "@common/types"
import Alert from "@common/alert"

export type MultiSelectValueProps = Array<string | number> | undefined

export type MultiSelectProps = {
  type?: "checkbox" | "radio" | "tag"
  id: string
  required?: boolean
  options: Options
  onChange: (keys: MultiSelectValueProps) => void
  isSingleSelect?: boolean
  allowSearchAfter?: number
  value?: MultiSelectValueProps
}

export default function MultiSelect({
  type = "checkbox",
  options,
  onChange,
  isSingleSelect,
  allowSearchAfter,
  value,
  required,
  id,
}: MultiSelectProps) {
  const [isError, setIsError] = useState<boolean>(false)
  const [selectionState, setSelectionState] = useState<MultiSelectValueProps>(value)
  const [transformedOptions, setTransFormedOptions] = useState(
    transformOptionsForListSearch(options || {})
  )
  useEffect(() => {
    if (!value) return
    setSelectionState(value)
  }, [value])

  useEffect(() => {
    if (!options) return
    const newTransformedOptions = transformOptionsForListSearch(options)
    if (!isObjectsEqual(newTransformedOptions, transformedOptions))
      setTransFormedOptions(newTransformedOptions)
  }, [options])

  if (!options) return <></>
  const handleChange = (optionKey: string) => {
    let state = [...(selectionState || [])]

    const index = state.indexOf(optionKey)

    if (isSingleSelect) {
      if (index > -1) state = []
      else state = [optionKey]
    } else {
      if (index > -1) state.splice(index, 1)
      else state.push(optionKey)
    }

    setSelectionState(state)

    onChange?.(state)
    if (isEmpty(state) && required) {
      setIsError(true)
    } else {
      setIsError(false)
    }
  }

  function transformOptionsForListSearch(options: Options) {
    const transformed = {} as any
    for (const key in options) {
      transformed[key] =
        typeof options[key].display === "string" ? options[key].display : key
    }
    return transformed
  }

  return (
    <>
      <div
        className={joinClassNames(
          "w-full h-full overflow-auto",
          type === "tag" ? "flex flex-wrap gap-1" : "space-y-4 "
        )}
      >
        {allowSearchAfter &&
        Object.keys(transformedOptions).length > allowSearchAfter ? (
          <div className="h-52 overflow-auto">
            <ListSearch
              list={transformedOptions}
              initSelectedKeys={selectionState as Array<string>}
              multiSelectList={false}
              onMultiSelectList={(selectedKeys: MultiSelectValueProps) =>
                onChange?.(selectedKeys)
              }
              infiniteScroll
              renderListUnit={(optionKey) => {
                return (
                  <div className="py-2" key={optionKey}>
                    {renderItem(optionKey)}
                  </div>
                )
              }}
              searchContainerClasses="flex px-2 rounded-md border bg-background divide-x divide-border"
            />
          </div>
        ) : (
          Object.keys(options).map((optionKey) => renderItem(optionKey))
        )}
      </div>
      {isError && <Alert variant="simpleError">This field is required</Alert>}
    </>
  )

  function renderItem(optionKey: string) {
    const isSelected =
      !isNullOrEmpty(selectionState) && selectionState.includes(optionKey)
    return (
      <div key={optionKey}>
        {isSingleSelect
          ? renderRadioStyled(optionKey, isSelected)
          : type == "tag"
            ? renderTag(optionKey, isSelected)
            : renderCheckboxStyled(optionKey, isSelected)}
      </div>
    )
  }

  function renderTag(optionKey: string, isSelected: boolean) {
    return (
      <div className="cursor-pointer">
        <Tag
          onClick={() => handleChange(optionKey)}
          color={isSelected ? "primary" : "background"}
        >
          <div className="p-1">{options[optionKey].display}</div>
        </Tag>
      </div>
    )
  }

  function renderRadioStyled(optionKey: string, isSelected: boolean) {
    if (options[optionKey])
      return (
        <CheckboxStyled
          key={optionKey}
          checked={isSelected}
          isRadio={true}
          id={id + optionKey}
          onChange={() => handleChange(optionKey)}
          label={{
            label: options[optionKey].display,
            labelPosition: "right",
            font: "normal",
          }}
        />
      )
  }

  function renderCheckboxStyled(optionKey: string, isSelected: boolean) {
    if (options[optionKey])
      return (
        <CheckboxStyled
          key={optionKey}
          checked={isSelected}
          id={id + optionKey}
          onChange={() => handleChange(optionKey)}
          label={{
            label: options[optionKey].display,
            labelPosition: "right",
            font: "normal",
          }}
        />
      )
  }
}
