import { Option, Size } from "@common/types"
import Svg from "../svg"
import { getListedOptionClasses } from "@common/hooks/useDropdown"
import CheckboxStyled from "@common/forms/inputs/checkboxStyled"
import { joinClassNames } from "@common/lib/util"
import { MouseEventHandler, ReactElement, ReactNode } from "react"

interface DropdownOptionProps {
  option: Option
  selected?: boolean
  showSelectedIcon?: boolean
  isHoverable?: boolean
  showCheckedIcon?: boolean
  onMouseEnter?: MouseEventHandler<HTMLDivElement>
  elementRef?: any
  onSelect?: (option: Option, e: any) => void
  disabled?: boolean
  size?: Size
}
export function DropdownOption({
  option,
  selected,
  onSelect,
  disabled,
  showSelectedIcon = true,
  showCheckedIcon,
  size,
  onMouseEnter,
  elementRef,
}: DropdownOptionProps) {
  const handleListOptionClick = (e: any) => {
    if (disabled || option.disabled) e.stopPropagation()
    else onSelect?.(option, e)
  }

  return (
    // button causes button in button warnings and hydration errors
    //eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      key={option.key}
      onClick={handleListOptionClick}
      //disabled={option?.disabled || disabled}
      className={getListedOptionClasses(
        !!selected,
        option.disabled || disabled,
        size
      )}
      onMouseEnter={onMouseEnter}
      ref={elementRef}
    >
      <DropdownOptionContent
        icon={option.icon}
        display={option.display}
        selected={selected}
        showSelectedIcon={showSelectedIcon}
        showCheckedIcon={showCheckedIcon}
      />
    </div>
  )
}

export function DropdownOptionContent({
  icon,
  display,
  selected,
  showSelectedIcon,
  disabled,
  showCheckedIcon,
}: {
  icon?: ReactNode
  display: ReactNode
  selected?: boolean
  showSelectedIcon?: boolean
  disabled?: boolean
  showCheckedIcon?: boolean
}) {
  return (
    <>
      <div
        className={joinClassNames(
          "flex justify-start items-center gap-2",
          selected && showSelectedIcon ? "w-[calc(100%_-_16px)]" : "w-full"
        )}
      >
        {showCheckedIcon && (
          <CheckboxStyled id={undefined} checked={selected} disabled={disabled} />
        )}
        {typeof icon == "string" ? (
          <Svg
            classes="w-4 h-4 text-secondary"
            tooltipContent={undefined}
            name={icon}
            strokeWidth={2}
          />
        ) : (
          icon
        )}
        {display && (
          <span
            className={` block truncate my-auto text-primary w-full ${
              selected ? "font-semibold " : ""
            }`}
          >
            {display}
          </span>
        )}
      </div>
      {selected && showSelectedIcon ? (
        <div className="my-auto flex justify-end">
          <Svg
            tooltipContent={undefined}
            name="check"
            classes="w-4 h-4"
            strokeWidth={2}
          />
        </div>
      ) : null}
    </>
  )
}
