import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  Dispatch,
} from "react"

type FormConfigs = Record<string, any>

interface FormState {
  values: FormConfigs
  errors: Record<string, string | Array<string> | undefined | null>
  isLoading: boolean
}

interface FormAction {
  type: "SET_VALUES" | "SET_VALUE" | "SET_ERROR_MESSAGE" | "SET_LOADING"
  payload: any
}

const initialState: FormState = {
  values: {},
  errors: {},
  isLoading: false,
}

const FormContext = createContext<{
  state: FormState
  dispatch: Dispatch<FormAction>
}>({
  state: initialState,
  dispatch: () => null,
})

const formReducer = (state: FormState, action: FormAction): FormState => {
  switch (action.type) {
    case "SET_VALUES":
      return { ...state, values: action.payload }
    case "SET_VALUE":
      return {
        ...state,
        values: { ...state.values, [action.payload.key]: action.payload.value },
      }
    case "SET_ERROR_MESSAGE":
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.key]: action.payload.message,
        },
      }
    case "SET_LOADING":
      return { ...state, isLoading: action.payload }
    default:
      return state
  }
}

export const FormContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(formReducer, initialState)

  return (
    <FormContext.Provider value={{ state, dispatch }}>
      {children}
    </FormContext.Provider>
  )
}

export const useFormContext = () => useContext(FormContext)

export const useForm = () => {
  const { state, dispatch } = useFormContext()

  const setValues = (values: Record<string, any>) => {
    dispatch({ type: "SET_VALUES", payload: values })
  }

  const setValue = (key: string, value: any) => {
    dispatch({ type: "SET_VALUE", payload: { key, value } })
  }

  const getValues = () => {
    return state.values
  }

  const getValue = (key: string) => {
    return state.values[key]
  }

  //   const setErrors = (message: string | null) => {
  //     dispatch({ type: "SET_ERROR_MESSAGE", payload: message })
  //   }
  const setError = (key: string, message: any) => {
    dispatch({ type: "SET_ERROR_MESSAGE", payload: { key, message } })
  }

  const setLoading = (isLoading: boolean) => {
    dispatch({ type: "SET_LOADING", payload: isLoading })
  }

  return {
    state,
    setValues,
    setValue,
    getValues,
    getValue,
    // setErrors,
    setError,
    setLoading,
  }
}
