import React, { createContext, useEffect, useState, ReactNode } from "react"
import { joinClassNames } from "@common/lib/util"

interface AccContextType {
  selectedIndexes: number[]
  setSelectedIndexes: React.Dispatch<React.SetStateAction<number[]>>
  onlyOneOpen: boolean
  unmountOnClose: boolean
}

interface AccordionProps {
  children: ReactNode
  defaultSelectedIndexes?: number[] | null
  onlyOneOpen?: boolean
  unmountOnClose?: boolean
}

interface AccordionHeaderProps {
  children: (props: {
    isSelected: boolean
    select: (index: number) => void
  }) => ReactNode
  index: number
}

interface AccordionBodyProps {
  children: (isSelected: boolean) => ReactNode
  index: number
  className?: string
}

const AccContext = createContext<AccContextType | undefined>(undefined)

export const Accordion = ({
  children,
  defaultSelectedIndexes = null,
  onlyOneOpen = true,
  unmountOnClose = false,
}: AccordionProps) => {
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>(
    defaultSelectedIndexes || []
  )

  useEffect(() => {
    if (defaultSelectedIndexes) setSelectedIndexes(defaultSelectedIndexes)
  }, [defaultSelectedIndexes])

  return (
    <AccContext.Provider
      value={{ selectedIndexes, setSelectedIndexes, onlyOneOpen, unmountOnClose }}
    >
      {children}
    </AccContext.Provider>
  )
}

const AccordionHeader = ({ children, index }: AccordionHeaderProps) => (
  <AccContext.Consumer>
    {({ selectedIndexes, setSelectedIndexes, onlyOneOpen }) => {
      return children({
        isSelected: selectedIndexes.includes(index),
        select: (index: number) =>
          setSelectedIndexes(() => {
            if (selectedIndexes.includes(index)) {
              const filteredIndexes = selectedIndexes.filter(
                (item) => item !== index
              )
              return filteredIndexes
            } else {
              if (onlyOneOpen) {
                return [index]
              }
              return [...selectedIndexes, index]
            }
          }),
      })
    }}
  </AccContext.Consumer>
)

const AccordionBody = ({ children, index, className }: AccordionBodyProps) => (
  <AccContext.Consumer>
    {({ selectedIndexes, unmountOnClose }) => {
      const isSelected = selectedIndexes.includes(index)
      if (unmountOnClose) return isSelected ? children(isSelected) : null
      return (
        <div className={joinClassNames(isSelected ? "block" : "hidden", className)}>
          {children(isSelected)}
        </div>
      )
    }}
  </AccContext.Consumer>
)

Accordion.Header = AccordionHeader
Accordion.Body = AccordionBody

export default Accordion
