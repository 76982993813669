import { useState, useEffect, Fragment } from "react"
import {
  ErrorCodes,
  getDetailsFromForm,
  isFormCompleted,
} from "@common/lib/forms.utils"
import Input from "./input"
import Alert from "@common/alert"
import Range from "@common/forms/inputs/range"
import MultiSelect from "@common/forms/inputs/multiSelect"
import ComboboxStyled from "@common/dropdowns/comboboxStyled"
import Button from "@common/buttons/button"
import Svg from "@common/svg"
import {
  deepCopyObject,
  hitUrl,
  isArray,
  isNullOrEmpty,
  isObjectsEqual,
  joinClassNames,
} from "@common/lib/util"
import AccordionSingle from "@common/accordion/accordionSingle"
import { Header } from "@common/header/headerUnit"
import ReCAPTCHA from "react-google-recaptcha"
import PaletteUnit from "@common/theme/paletteUnit"
import { Status } from "@common/types"
import CheckboxStyled from "@common/forms/inputs/checkboxStyled"
import ListboxStyled from "@common/dropdowns/listboxStyled"
import MultiColorPicker from "@common/forms/inputs/multiColorPicker"
import DatePicker from "@common/datePickers/datePicker"
import DatePickerRange from "@common/datePickers/datePickerRange"
import Label from "@common/forms/label"
import useDebouncedCallback from "@common/hooks/useDebounceCallback"
import MentionInput from "@common/forms/inputs/mention"
import { FormContextProvider, useForm } from "@common/forms/formContext"

/** 
 *
const configs = {
  'email': { field: "input", type: "email", label: 'Work Email', value: '', required: true },
  'name': { field: "input", label: 'Full Name', value: '', required: true },
  'company': { field: "input", label: 'Company', value: '', required: true  },
  'password': { field: "input", type: "password", label: 'Password', value: '', required: true, minChars: 4 }
  'message': { field: "textarea", label: 'Message', value: '', required: false, labelPosition: "left", placeholder: "Tell us about your requirements.", rows: 4, className: "textarea-nowrap" }

  'cat_id': 
  {
    field: "listbox",
      label: "Label type",
      value: "diy",
      options: {
        diy: { key: "diy", display: "DIY" },
        headphones: { key: "headphones", display: "Headphones" },
        mic: { key: "mic", display: "Mouse" },
      },
      required: true,
  },
  'name': 
  { 
    field: "input", 
    label: 'Group Name', 
    value: "Test Group Name",
    placeholder: "Enter group name.", 
    required: true,
    invisible: true, //this makes the field invisible in form.
    validate: (val) => !val && {
        isError: true,
        message: "Password must not be lesser than 4 characters",
      },
  },
  'info_for_something': 
  { 
    field: "info", // this type will automatically be removed from submit details 
    value: 'General purpose info.', 
  },
  designation: {
    field: "listbox",
    label: "Position",
    value: { key: "Product Manager", display: "Product Manager" },
    containerWidthClasses: "w-full",
    options: options,
    required: true,
    children: {   // only works for listbox
      // this will show a input box below selector if the key matched
      other:{
        rules:{
           value: "Other", 
        }
        placeholder: "Position",
        field: "input",
        maxChars: 30,
        required: true,
        label: "",
        value: "",
      }
    },
  },
  users_range: {    //RENDERS A RANGE SELECT SLIDER
    field: "range",
    min: 20,          //MIN VALUE AT LEFT END
    max: 200,         // MAX VALUE AT RIGHT END
    step: 1,          // SLIDE STEP POINT (WILL SLIDE WITH MULTIPLE OF THIS VALUE)
    label: "How many people in your team need access?", 
    value: 20,        // DEFAULT SLECTED VALUE (REQUIRED)
  },
  api_access: {       
    field: "radioList", // RENDERS A QUESTION WITH MULTIPLE CHOICES
    label: "Would you like API access?",
    label: "Would you like API access?", // QUESTION 
    options: {
      "no": {
        "display": "No Thanks"
      },
      "yes": {
        "display": "Yes Please"
      },
      "null": {
        "display": "Don't Care"
      }
      }, // OPTIONS TO SELECT FROM
    value: "No, thankyou", // DEFAULT SELECTED VALUE  (REQUIRED)
  },
  users: {
      field: "combobox", // Multiselect comboBox
      label: "Users",
      options: {key1:{key:"key", display:"display"}, ...},
      value: "", // selected keys [key1,key2,...]
      required: true,
      labelPosition: "top",
      placeholder: "Add users",
  },
}
*/

// hit Url - > handle in primary button call back
// getDetailsForSubmit -> function that allows parent to take the "details" object from formComponent and generate the final details that needs to be submitted.
function FormComponent({
  formConfig,
  initialValues,
  isActions = true, //default true, to hide action button pass isActions=false
  onChange,
  primaryAction,
  secondaryAction,
  forceActivePrimaryButton = false, // default null. if true, the main button will show up as active as opposed to waiting for completion of *required fields
  isSingleFullButton = false,
  onSecondaryActionClick,
  formHeader = "",
  formSubText = "",
  formBottomText,
  conClasses = "",
  getDetailsForSubmit,
  submitFunction,
  onSubmit,
  onSubmitResponse,
  onSubmitSuccess,
  onSubmitFail,
  enforceEdit = false, // ⚠️ boolean : WHEN TRUE, ENABLES THE "EDIT MODE" & WILL FORCE THE isFormCompleted TO CHECK IF VALUES ARE EDITED (CHECK isFormCompleted FOR DETAILS)
  logResponse = false,
  size = "sm",
  onChangeDelay = false,
  /**
   * below props are used internally for recursive children layout. User of this <Form/> component can ignore these props.
   */
  parentFieldValue = null,
  isBaseForm = true,
  formHeaderAlign = "center",
}) {
  const [configs, setConfigs] = useState(formConfig)
  const [errorMessage, setErrorMessage] = useState(null)
  // const [isLoading, setIsLoading] = useState(false)

  const {
    setValue,
    setValues,
    setError,
    setLoading,
    getValues,
    getValue,
    state: { isLoading },
  } = useForm()

  const formValues = getValues()
  const debounce = useDebouncedCallback(onChange, 1000)

  useEffect(() => {
    const values = getInitialValues(formConfig, initialValues)
    if (isBaseForm) {
      if (!isObjectsEqual(configs, formConfig)) setConfigs(formConfig)
      if (!isObjectsEqual(formValues, values)) setValues(values)
    } else {
      if (!isObjectsEqual(configs, formConfig)) setConfigs(formConfig)
    }
  }, [formConfig, isBaseForm, initialValues])

  useEffect(() => {
    if (onChange && !isNullOrEmpty(formValues))
      (onChangeDelay ? debounce : onChange)(
        deepCopyObject(formValues),
        isFormCompleted(configs, formValues, enforceEdit)
      )
  }, [formValues])

  if (configs == undefined) return <div></div>

  function getInitialValues(formConfigs, initialValues = {}) {
    return {
      ...getDetailsFromForm(formConfigs),
      ...initialValues,
    }
  }

  return isBaseForm ? (
    <div className={conClasses || "  "}>
      {(formHeader || formSubText) && (
        <div className="mt-4 mb-8">
          <Header variant="h6" alignment={formHeaderAlign}>
            <Header.MainHeader>{formHeader}</Header.MainHeader>
            <Header.Description>{formSubText}</Header.Description>
          </Header>
        </div>
      )}
      <form
        action="#"
        method="POST"
        className=" flex flex-col space-y-4 "
        onSubmit={(e) => handleSubmit(e)}
      >
        {Object.keys(configs).map(
          (key) =>
            !getIsVisible(configs, key) &&
            key !== "advanced" && (
              <div key={key}>{renderFormField({ key, details: configs[key] })}</div>
            )
        )}
        {renderAdvancedFormFields(configs.advanced)}
        {errorMessage ? (
          <Alert variant="error">{errorMessage}</Alert>
        ) : isActions == false ? null : (
          <></>
        )}

        {isActions && (
          <div className="flex flex-row-reverse gap-2 pt-4">
            <Button
              size={size}
              disabled={
                forceActivePrimaryButton
                  ? false
                  : !isFormCompleted(configs, formValues, enforceEdit)
              }
              variant="primary"
              width={isSingleFullButton ? "full" : "default"}
              isLoading={isLoading}
              disabledMsg={
                isLoading ? null : (
                  <Alert variant="simpleError">
                    {enforceEdit
                      ? "Nothing to update"
                      : "Required fields must not be empty."}
                  </Alert>
                )
              }
            >
              {primaryAction || "Submit"}
            </Button>
            {secondaryAction && (
              <Button
                type="button"
                variant="minimal"
                onClick={() => handleSecondaryClick()}
                size={size}
              >
                {secondaryAction}
              </Button>
            )}
          </div>
        )}
        {formBottomText && (
          <div className="mt-5 text-sm text-primary">{formBottomText}</div>
        )}
      </form>
    </div>
  ) : (
    <div className="mt-2 space-y-4">
      {Object.keys(configs).map(
        (key) =>
          !getIsVisible(configs, key) &&
          key !== "advanced" &&
          renderFormField({ key, details: configs[key] })
      )}
    </div>
  )

  function renderAdvancedFormFields(advancedConfigs) {
    const advancedFields = advancedConfigs
      ? Object.keys(advancedConfigs).map(
          (key) =>
            !getIsVisible(advancedConfigs, key) && (
              <div key={key}>
                {renderFormField({
                  key,
                  details: advancedConfigs[key],
                  isAdvanced: true,
                })}
              </div>
            )
        )
      : []

    return (
      advancedFields &&
      advancedFields.length > 0 && (
        <AccordionSingle
          header={
            <div className=" normal-case font-bold text-base">Advanced Options</div>
          }
          showHeader={true}
          isOpen={false}
          expandIconPosition="left"
        >
          <div className="my-4 space-y-4">{advancedFields}</div>
        </AccordionSingle>
      )
    )
  }

  function renderFormField({ key, details, groupKey, index, isAdvanced }) {
    if (!details || key === "advanced") return
    let value

    if (
      parentFieldValue &&
      details.rules &&
      parentFieldValue != details["rules"].value
    )
      return <></>

    if (groupKey) {
      const groupvalues = getValue(groupKey) || []
      const valueObject = isArray(groupvalues) ? groupvalues.at(index) : {}
      value = valueObject?.[key]
    } else value = getValue(key)

    const labelId = groupKey ? `${groupKey}.${index}.${key}` : key
    return isBaseForm ? (
      <div className={details.direction == "horizontal" ? "flex gap-2" : ""}>
        {renderFormFields()}
      </div>
    ) : (
      renderFormFields()
    )

    function renderFormFields() {
      const { children, ...fieldProps } = details
      return (
        <>
          {/* TODO: Achive this via Form rules */}
          {fieldProps.field === "listbox" &&
          !fieldProps.showSingleItem &&
          Object.keys(fieldProps?.options || {}).length == 1 ? (
            <></>
          ) : (
            <Label
              key={labelId}
              {...fieldProps.label}
              label={fieldProps.groupIndex > 0 ? "" : fieldProps?.label?.label}
              required={fieldProps.required}
              id={labelId}
            >
              {renderInput(fieldProps)}
            </Label>
          )}
          {isChildrenVisible(details, value) && (
            <FormComponent
              formConfig={details.children}
              isActions={false}
              // onChange={handleChildFormChange}
              parentFieldValue={value}
              isBaseForm={false}
            />
          )}
        </>
      )
    }

    function renderInput(fieldProps) {
      switch (fieldProps.field) {
        case "custom":
          return <div>{fieldProps.renderCustom?.()}</div>

        case "info":
          return <Alert variant={fieldProps?.type}>{fieldProps.value}</Alert>

        case "input":
        case "multi_input":
        case "textarea":
          return (
            <Input
              {...fieldProps}
              key={labelId}
              id={labelId}
              required={fieldProps.required}
              field={fieldProps.field || "input"}
              value={value}
              rows={fieldProps.field == "textarea" ? fieldProps.rows : undefined}
              fieldClasses={fieldProps.classes}
              onChange={(text, message) =>
                handleChange(text, key, message, groupKey, index)
              }
              size={size}
            />
          )

        case "range":
          return (
            <Range
              {...fieldProps}
              value={value}
              id={key}
              onChange={(value) => {
                handleChange(value, key, undefined, groupKey, index)
              }}
            />
          )

        case "radioList":
          return (
            <div className="p-2 rounded-md border max-h-60 overflow-auto">
              <MultiSelect
                options={fieldProps.options}
                required={fieldProps.required}
                id={key}
                onChange={(values) =>
                  handleChange(values[0], key, undefined, groupKey, index)
                }
                value={value ? [value] : null}
                isSingleSelect
              />
            </div>
          )
        case "multiSelect":
          return (
            <div className="p-2 rounded-md border max-h-60 overflow-auto">
              <MultiSelect
                options={fieldProps.options}
                required={fieldProps.required}
                id={key}
                onChange={(values) => {
                  handleChange(values, key, undefined, groupKey, index)
                }}
                value={value}
              />
            </div>
          )

        case "checkbox":
          return (
            <CheckboxStyled
              {...fieldProps}
              id={key}
              checked={Boolean(value)}
              onChange={(event) =>
                handleChange(event.target.checked, key, undefined, groupKey, index)
              }
              // TODO - Currently, label in checkbox is inconsistent with the rest of the usage i.e label is used for internal purposes rather than external label (like the rest of components).
              // Fix - label usage should be consistent and introduce a new "label" for checkbox. Maybe similar to option in multiselect? Or its own independent structure?
              label={{}}
            />
          )

        case "listbox":
          return (
            <ListboxStyled
              {...fieldProps}
              defaultSelectedKey={value ? value : undefined}
              required={fieldProps.required}
              placeHolderText={fieldProps.placeholder}
              onSelect={(selectedKey) =>
                handleChange(selectedKey, key, undefined, groupKey, index)
              }
              size={size}
            />
          )
        case "combobox":
          return (
            <ComboboxStyled
              {...fieldProps}
              onSelect={(selectedKeys) =>
                handleChange(selectedKeys, key, undefined, groupKey, index)
              }
              placeHolderText={fieldProps.placeholder}
              defaultSelectedKeys={value}
              required={fieldProps.required}
              id={key}
              size={size}
            />
          )

        case "recaptcha":
          return (
            fieldProps.siteKey && (
              <div className=" w-full py-2 flex items-center justify-center">
                <ReCAPTCHA
                  sitekey={fieldProps.siteKey}
                  theme={fieldProps.theme}
                  onChange={(token) =>
                    handleChange(token, key, undefined, groupKey, index)
                  }
                />
              </div>
            )
          )

        case "color_picker":
          return (
            <PaletteUnit
              label={fieldProps.label}
              colorCode={value}
              onColorChange={(pickedColor) =>
                handleChange(pickedColor, key, undefined, groupKey, index)
              }
              required={fieldProps.required}
              id={key}
            />
          )

        case "multi_color_picker":
          return (
            <MultiColorPicker
              onChange={(pickedColors) =>
                handleChange(pickedColors, key, undefined, groupKey, index)
              }
              colors={value}
              colorsCount={fieldProps.valueCount}
            />
          )
        case "date_picker":
          return (
            <DatePicker
              onChange={(date) =>
                handleChange(date, key, undefined, groupKey, index)
              }
              date={value}
              placeHolder={fieldProps.placeholder}
              required={fieldProps.required}
              size={size}
            />
          )
        case "date_picker_range":
          return (
            <DatePickerRange
              onChange={(dateRange) =>
                handleChange(dateRange, key, undefined, groupKey, index)
              }
              range={value}
              required={fieldProps.required}
              size={size}
            />
          )
        case "mentions":
          return (
            <MentionInput
              value={value}
              options={fieldProps.options}
              onChange={(value) =>
                handleChange(value, key, undefined, groupKey, index)
              }
            />
          )
        case "group":
          return renderArrayField({
            groupKey: key,
            fieldConfig: details,
            isAdvanced,
          })
      }
    }
  }

  function handleGroupActionClick(value, groupKey, groupIndex, action, isAdvanced) {
    function updateConfigs(value, config) {
      config.minCount = value.length
      const updatedConfigs = {
        ...configs,
        ...(isAdvanced
          ? { advanced: { ...configs.advanced, [groupKey]: config } }
          : { [groupKey]: config }),
      }
      setConfigs(updatedConfigs)
    }

    const updatedValue = [...value]
    switch (action) {
      case "add":
        {
          const newValueToAdd = value[groupIndex]
          if (newValueToAdd !== undefined) {
            updatedValue.splice(groupIndex + 1, 0, newValueToAdd)
            setValue(groupKey, updatedValue)
          }
        }
        break
      case "remove":
        if (updatedValue.length === 1) {
          setValue(
            groupKey,
            updatedValue.map((v) => {
              return Object.keys(v).reduce((obj, key) => ({ [key]: "" }), {})
            })
          )
        } else if (groupIndex >= 0 && groupIndex < updatedValue.length) {
          updatedValue.splice(groupIndex, 1)
          setValue(groupKey, updatedValue)
        }
        break
      default:
        return
    }

    const config = isAdvanced ? configs.advanced?.[groupKey] : configs[groupKey]
    if (config) updateConfigs(updatedValue, config)
  }

  function renderArrayField({ groupKey, fieldConfig, isAdvanced }) {
    const {
      minCount = 1,
      fields = {},
      layout,
      showAction = true,
      enableReset,
    } = fieldConfig
    const pValue = getValue(groupKey) || []
    // const vLength = pValue.length
    // const count = vLength > 0 ? vLength : minCount
    const configsArray = Array(minCount).fill(fields)
    return (
      <div className=" border rounded-md px-2" key={groupKey}>
        {configsArray.map((config, index) => (
          <Fragment key={`${groupKey}.${index}`}>
            <div
              className={joinClassNames(
                "my-2 relative",
                layout === "horizontal"
                  ? "flex w-full items-start gap-2"
                  : `space-y-4`
              )}
            >
              {Object.entries(config).map(([subKey, subConfig]) => (
                <div key={`${groupKey}.${index}.${subKey}`} className="flex-1">
                  {renderFormField({
                    key: subKey,
                    details: subConfig,
                    groupKey,
                    index,
                  })}
                </div>
              ))}
              <div
                className={joinClassNames(
                  layout === "horizontal"
                    ? "mt-[30px]"
                    : "absolute top-0 right-0 !mt-0"
                )}
              >
                {showAction && (
                  <GroupAction
                    key={index}
                    minCount={minCount}
                    value={pValue}
                    enableReset={enableReset}
                    handleClick={(action) =>
                      handleGroupActionClick(
                        pValue,
                        groupKey,
                        index,
                        action,
                        isAdvanced,
                        enableReset
                      )
                    }
                  />
                )}
              </div>
            </div>
            {index + 1 !== minCount && <span className="border-t block my-2" />}
          </Fragment>
        ))}
      </div>
    )
  }
  function GroupAction({ value, handleClick, enableReset }) {
    const canRemove = value.length > 1
    return (
      <>
        <Button
          type="button"
          variant="minimal"
          size="icon"
          onClick={() => handleClick("add")}
        >
          <Svg name="plus" classes="w-5 h-5 text-gray-600 dark:text-gray-300" />
        </Button>
        <Button
          type="button"
          size="icon"
          onClick={() => handleClick("remove")}
          disabled={!enableReset && !canRemove}
          variant="minimal"
        >
          <Svg
            name="plus"
            classes="w-5 h-5 rotate-45 text-gray-600 dark:text-gray-300"
          />
        </Button>
      </>
    )
  }

  function handleSubmit(event) {
    event.preventDefault()
    event.stopPropagation() // for cases such as form within a form ex: Login > Forgot Password
    setErrorMessage(null)
    if (
      isFormCompleted(configs, formValues, enforceEdit) &&
      isFormValid(configs, formValues)
    ) {
      setErrorMessage(null)
      setLoading(true)
      submit()
    } else {
      setErrorMessage(ErrorCodes.MULTI)
    }
  }

  function isFormValid(form, formValues) {
    const errorMessages = {}
    for (const key in form) {
      if (Object.hasOwnProperty.call(form, key)) {
        const field = form[key]
        const { validate } = field
        const value = formValues?.[key]
        if (validate && validate instanceof Function) {
          const _value = typeof value == "string" ? value : value.key
          const { isError, message } = validate(_value)
          if (isError) {
            errorMessages[key] = message
          }
        }
      }
    }
    return Object.keys(errorMessages).length == 0
  }

  function handleChange(value, key, error, groupKey, index) {
    if (index !== undefined && groupKey) {
      const existingValue = getValue(groupKey) || []

      while (existingValue.length <= index) {
        existingValue.push({})
      }
      // existingValue = existingValue.map((obj = {}, i) => {
      //   if (!obj[key] && i !== index) return { ...obj, [key]: undefined }
      //   return obj
      // })
      const existingValueAtIndex = existingValue[index]
      const newValue = {
        ...existingValueAtIndex,
        [key]: value,
      }
      existingValue[index] = newValue
      setValue(groupKey, existingValue)
    } else setValue(key, value)

    if (error) setError(key, error)
  }

  async function submit() {
    let details = getValues()
    if (
      details.password &&
      details.confirm_password &&
      details.password !== details.confirm_password
    ) {
      setErrorMessage("Those passwords didn't match. Please try again.")
      setLoading(false)
      return
    }
    if (getDetailsForSubmit) details = getDetailsForSubmit(details)
    if (onSubmit) {
      onSubmit(details)
    }

    if (!submitFunction) {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
      if (onSubmitSuccess) onSubmitSuccess()
      return
    }

    const { data, status, message } = (await submitFunction(details)) || {}
    setLoading(false)
    if (onSubmitResponse) onSubmitResponse(data)
    if (status === Status.Success) {
      if (onSubmitSuccess) onSubmitSuccess(data)
      setErrorMessage(null)
    } else {
      setErrorMessage(message)
      if (onSubmitFail) onSubmitFail(message, data)
    }
    if (logResponse) submitGoogleForm(formHeader, details)
  }

  function handleSecondaryClick() {
    if (onSecondaryActionClick) onSecondaryActionClick()
  }

  function isChildrenVisible(details, value) {
    return !!details["children"] && !!value
  }
}

function submitGoogleForm(formHeader, details) {
  const url = `https://docs.google.com/forms/d/e/1FAIpQLSdL-I4gmEMAqypUqXg8QcemCNEmkE7eZ8N0IhVLhW3S8dUQXw/formResponse?usp=pp_url&entry.1395913944=${
    window.location.href
  }&entry.851483779=${formHeader}&entry.1923022617=${JSON.stringify(
    details
  )}&submit=Submit`
  hitUrl(url)
}

function getIsVisible(configs, key) {
  return configs[key]?.invisible
}

export default function Form(props) {
  return (
    <FormContextProvider>
      <FormComponent {...props} />
    </FormContextProvider>
  )
}
