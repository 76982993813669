import { isNullOrEmpty } from "@common/lib/util"
import { Options } from "@common/types"
import React, { useEffect, useState } from "react"
import { Mention, MentionsInput } from "react-mentions"

interface MentionInputProps {
  options: Options
  value?: string
  onChange: (value: string) => void
}
export default function MentionInput(props: MentionInputProps) {
  const { onChange } = props
  const options = Object.values(props.options || {}).map(({ key, display }) => ({
    id: key,
    display: display as string,
  }))

  const [value, setValue] = useState<string>()

  const sanitizeVal = (value?: string) => value?.replaceAll(/[# ]/g, "").trim()

  useEffect(() => {
    if (
      removeMarkup(sanitizeVal(value)) !== props.value &&
      !isNullOrEmpty(props.options)
    )
      setValue(addMarkup(props.value, options))
  }, [props.value, options])

  function removeMarkup(input = "") {
    const val = input.replace(/\[\[([^:]+)::([^\]]+)\]\]/g, "$2")
    return val
  }

  /**
   *
   * @example
   * const input = "key1:count + key2:count / key1:count";
   * const expectedOutput = "[[Var 1::key1:count]] + [[Var 2::key2:count]] / [[Var 1::key1:count]]";
   */
  function addMarkup(input?: string, variables: typeof options = []) {
    if (!input) return input
    const optionsMap = variables.reduce(
      (acc, variable) => {
        acc[variable.id] = variable.display
        return acc
      },
      {} as Record<string, string>
    )

    // Escape special characters in keys
    const keys = Object.keys(optionsMap).map((key) =>
      key.replace(/[-[\]{}()*+?.,\\^$|#\s:]/g, "\\$&")
    )
    const regex = new RegExp(keys.join("|"), "g")

    return input.replace(regex, (match) => {
      const display = optionsMap[match]
      return display ? `[[${display}::${match}]]` : match
    })
  }

  return (
    <MentionsInput
      value={value || ""}
      onChange={(_event, value) => {
        if (value.endsWith("#") && !value.endsWith(" #")) {
          value = value.slice(0, -1) + " #" // Adding a space before the "#" to trigger suggestions
        }
        setValue(value)
        onChange(removeMarkup(sanitizeVal(value)))
      }}
      rows={5}
      className="mentions"
      placeholder="Type # to get suggestions"
      allowSpaceInQuery
      allowSuggestionsAboveCursor
    >
      <Mention
        trigger="#"
        data={options}
        renderSuggestion={(_0, _1, highlightedDisplay) => (
          <div>{highlightedDisplay}</div>
        )}
        markup="[[__display__::__id__]]"
        style={{ backgroundColor: "var(--muted)" }}
      />
    </MentionsInput>
  )
}
