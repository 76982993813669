import React from "react"
import Button from "@common/buttons/button"
import Svg from "./svg"
import { Option } from "./types"
type OptionFormPropTypes = {
  query?: string
  handleOptionCreate?: (value: string) => void
  searchedOptions?: Option[]
}

const OptionForm = ({
  query,
  handleOptionCreate,
}: OptionFormPropTypes): JSX.Element => {
  const handleClick = () => {
    const inputText = query ? query.trim() : ""
    if (handleOptionCreate) handleOptionCreate(inputText)
  }
  return (
    <div className="sticky bottom-0 p-2">
      <Button width={"full"}  variant={"outline"} onClick={handleClick} type="button">
        <div className="flex gap-1 mr-1 items-center">
          <Svg tooltipContent={undefined} classes="w-5" name="plus" /> Create
        </div>
        {query ? (
          <div className="truncate font-bold">
            &#39;<span>{query}</span>&#39;
          </div>
        ) : (
          <div>New</div>
        )}
      </Button>
    </div>
  )
}

export default OptionForm
