import { useState } from "react"
import { isArray, joinClassNames, localSearch, mapObjectToArray } from "../lib/util"
import { Option, Options, Size } from "../types"

export enum sectionException {
  other = "unsectioned",
}
interface GroupedOptions {
  [key: string]: Option[]
}

//📌 merge the logic of channels into the following, follwed by the rendering methods
export function useDropdown(
  options?: Options,
  sortOptions = false,
  defaultSelectedKeys?: string[]
) {
  const optionsArray = mapObjectToArray(options, sortOptions ? "display" : null)
  const [groupedOptions, setGroupedOptions] = useState<GroupedOptions>(
    groupOptionsBySectionKey(optionsArray)
  )
  const [selectedKeys, setSelectedKeys] = useState(getSelectedKeys(optionsArray))

  function getSelectedKeys(optionsArray: Option[]) {
    return optionsArray
      .filter(
        (option) =>
          isArray(defaultSelectedKeys) &&
          defaultSelectedKeys?.includes(option.key as string)
      )
      .map(({ key }) => key)
  }

  function handleInputChange(input: string) {
    const resultKeys = localSearch(
      input,
      getSearchableOptions(groupOptionsBySectionKey(optionsArray))
    ) as string[]
    if (input) {
      const searchedOptions = optionsArray.filter((option) =>
        resultKeys.includes(option.key)
      )
      const searchedGroupedOptions = groupOptionsBySectionKey(searchedOptions)
      setGroupedOptions(searchedGroupedOptions)
    } else {
      setGroupedOptions(groupOptionsBySectionKey(optionsArray))
    }
  }

  const getSearchableOptions = (
    options: GroupedOptions,
    optionKeys: (keyof Option)[] = ["display"] // add any key of option whose value is searchable
  ): any => {
    //{ [key: string]: string }
    const searchableOption: any = {} //{ [key: string]: string }
    for (const sectionKey in options) {
      searchableOption[sectionKey] = sectionKey
      for (const section of options[sectionKey]) {
        const optionStrings = optionKeys.map((optionKey) => section[optionKey])
        searchableOption[section.key] = [sectionKey, ...optionStrings].join(" ")
      }
    }
    return searchableOption
  }

  function groupOptionsBySectionKey(options: Option[]): GroupedOptions {
    const grouped: any = {} //{ [key: string]: Option[] }
    options.forEach((option) => {
      if (!option?.invisible) {
        //@ts-expect-error this components will be removed so no need this type issue
        const sectionKey = option.section_key || sectionException.other
        if (!grouped[sectionKey]) {
          grouped[sectionKey] = []
        }
        grouped[sectionKey].push(option)
      }
    })

    return grouped
  }

  return {
    groupedOptions,
    selectedKeys,
    optionsArray,
    handleInputChange,
    setSelectedKeys,
  }
}

export function getListedOptionClasses(
  active: boolean,
  disabled?: boolean,
  size: Size = "xs" as Size
) {
  return joinClassNames(
    "w-full justify-between select-none flex items-center pr-2 rounded-md hover:bg-muted",
    active ? "bg-muted text-primary" : "text-secondary",
    disabled ? "opacity-50 cursor-not-allowed" : " cursor-pointer ",
    size == "xs" ? "size-xs" : "size-sm"
  )
}
